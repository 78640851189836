












































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ActionMethod } from 'vuex';
import { Action } from 'vuex-class';
import { debounce } from 'lodash';
import BaseAutoComplete from '@improve/common-components/src/components/core/BaseAutoComplete.vue';
import BaseUserAvatar from '@improve/common-components/src/components/widgets/BaseUserAvatar.vue';
import User from '@improve/common-utils/src/model/User';
import UserSearchParams from '@improve/common-utils/src/types/UserSearchParams';

@Component({
  name: 'UserSelector',
  components: {
    BaseAutoComplete,
    BaseUserAvatar
  }
})
export default class UserSelector extends Vue {
  @Action fetchUsers!: ActionMethod;

  users: Array<User> = [];

  get activeUsers(): Array<User> {
    return this.users.filter((user) => (
      user.firstName !== null && user.lastName !== null) && user.enabled);
  }

  loading = false;

  created(): void {
    this.fetch(20);
  }

  fullName(item: User): string {
    return `${item.firstName} ${item.lastName}`;
  }

  async fetch(size: number, name: string | null = null): Promise<void> {
    const searchParams = new UserSearchParams();
    searchParams.size = size;
    if (name !== null) searchParams.name = name.toLowerCase();

    this.loading = true;
    this.users = await this.fetchUsers({ params: searchParams });
    this.loading = false;
  }

  async search(searchString: string | null): Promise<void> {
    this.fetch(100, searchString);
  }

  handleSearch = debounce((searchString: string) => this.search(searchString), 300);
}
